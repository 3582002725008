.UnlimitedAgentsDropDownOuterDiv{
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
gap:10px;
margin-top: -10px;
margin-bottom: 25px;
}

.UnlimitedAgentsDropDownText{
    color: #8C8C8C;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
}

.UnlimitedAgentsDropDownSelectionDiv{
   width: 152px;
   height: 45px;
   border: 3px solid #E9E9E9;
   border-radius: 140px;
   display: flex;
    align-items: center;
    justify-content: center;
}

.UnlimitedAgentsDropDownSelection{
    width: 100%;
    margin:0px 20px 0px 20px;
    border:none;
    outline: 0;
}