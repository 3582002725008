.custom-modal-width .modal-dialog {
  max-width: 620px;
  width: 100%;
}

.custom-modal-width .modal-content {
  position: relative;
  overflow: visible;
}

.mainContainer {
  width: 620px;
  height: 780px;
  overflow: scroll;
}
.heading{
  color: var(--Black, #000);
  text-align: center;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.missedCall{
  background-color:'lightred';
  border:'1px solid red';
}
.contactName{
  color: var(--Black, #000);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.contactNumber{
  color: #5C5C5C;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.callTime{
  color: #5C5C5C;
  text-align: right;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.callDate{
  color: #5C5C5C;
  text-align: right;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.callDescription{
  color: #5C5C5C;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.dispositionHeading{
  color: #5C5C5C;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.disposition{
  color: var(--White, #FFF);
  font-family: Montserrat;
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: #7190FF;
}
.sentimentHeading{
  color: var(--Black, #000);
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.positiveSentiment{
  color: var(--Alohaa-Green, #07AA3F);
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.neutralSentiment{
  color: #FEB017;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.negativeSentiment{
  color: #F8153B;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.greyOutSentiment{
  color: var(--Gray, #9B9B9B);
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.badgeBg{
  background: #7190FF;
}
.keywordsHeading{
  color: var(--Black, #000);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.keyword{
  color: var(--White, #FFF);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: #7190FF;
}
.summaryHeading{
  color: var(--Black, #000);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.summary{
  color: var(--Gray, #9B9B9B);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
/* Audio Player */
#myinput {
  border-radius: 8px;
  height: 4px;
  width: 400px;
  outline: none;
  margin-right: 10px;
  margin-left: 10px;
  -webkit-appearance: none;
  cursor: ew-resize;
}
input[type='range']::-webkit-slider-thumb {
  width: 6px;
  -webkit-appearance: none;
  height: 12px;
  background: black;
  border-radius: 2px;
}
/* Loader */
.lottieGif{
  position: relative;
}
.loaderText{
  position: absolute;
  margin-top:300px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.5px;
  text-align: center;
}