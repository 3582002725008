@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
:root {
  --primary-color: #ea4b4b;
  --primary-color1: #ea4b4b;
  --secondary-color: #196cca;
  --secondary-color1: #282828;
  --secondary-color2: #d2d3d4;
  --white-color: #ffffff;
  --light-green-color: #4caf50;
  --yellowish-orange-color: #f1bb37;
  --orange-color: #ef7d24;
  --light-grey-color: #f1f2f2;
  --light-grey-bg-color: #f2f2f2;
  --lighter-grey-input-bg-color: #f7f7f7;
  --mid-grey-color: #d1d3d4;
  --dark-grey-color: #aeb0b1;
  --light-grey-input-bg-color: #e3e3e3;
  --black-color: #000000;
  --red-color: #ee3048;
  --text-green-color: #07aa3f;
  --blue-color: #0a82ff;
  --dark-blue-color: #196cca;
  --text-grey-color: #6d6e71;
  --hover-color: #80a8ad;
  --content-container-border: #d1d3d4;
  --bluewish-blue-color: #42a1e2;
}

* {
  font-family: Montserrat;
}
