.drag-and-drop {
  border: 1px dashed #d1d3d4;
  height: 128px;
  border-radius: 5px;
  padding: 1rem;
  text-align: center;
  transition: border-color 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  margin-bottom: 16px;
}

.dragging {
  border-color: #007bff;
}

.dnd-file-input {
  display: none;
}

.DnD-label {
  cursor: pointer;
  color: #196cca;
  font-size: 14px;
  font-weight: 500;
}
