.campaignsMainContainer {
    height: 326px;
  margin: 0px 0px 0px;
  padding: 24px 16px 24px 24px;
  border: solid 1px #d2d3d4;
  background-color: #fff;
}

.Overview {
    width: 89px;
    height: 22px;
    margin: 5px 419px 29px 11px;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: left;
    color: #196cca;
  }


  .TableFilter{
    height: 80px;
    display: flex;
    align-items: center;
    /* justify-content: center; */
  }

  .CampaignSearchBar{
    display: flex;
    position: absolute;
    right: 16px;
  }