

.NumberDialerButton {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 16px;
    text-decoration: none;
    width: calc(100% - 16px);
    background-color: var(--light-green-color);
    color: var(--white-color);
    margin: 8px;
    border-radius: 4px;
    border: 0;
    max-height: 54px;
    /* for making text smooth */
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  .NumberDialerButton:hover {
    background-color: var(--hover-color);
    text-decoration: none;
  }
  
  .NumberDialerButtonTextWrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .NumberDialerText {
    font-size: 12px;
    font-weight: 600;
  }
  
  .NumberDialerIcon {
    height: 14px;
  }
  
  .Dialer-container {
  }
  
  .Dialer-title {
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: var(--light-green-color);
  }
  
  .Dialer-title .Dialer-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 5px solid var(--light-green-color);
    height: 120px;
    width: 120px;
  }
  .Dialer-title .Dialer-icon svg {
    height: 80px;
  }
  
  .Dialer-title .Dialer-title-text {
    text-align: center;
    padding-top: 16px;
    font-size: 18px;
    font-weight: 600;
  }
  .Dialer-inputs {
    padding: 0 16px;
    display: flex;
    flex-direction: column;
  }

  .Dialer-inputs input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  .Dialer-inputs input[type=number] {
    -moz-appearance: textfield;
  }

  .Dialer-action {}
  .Dialer-info-text {
    font-size: 12px;
    padding: 16px;
    padding-bottom: 0;
  }
  