.side-modal-container {
    position: relative;
    width: 134px;
  height: 147px;
  margin: 0 0 0 34px;
  padding: 0 0 49px 24px;
  background-color: #364757;
  }
  
  .Rectangle-9993 {
    position: absolute;
    top: 0;
    right: 0;
        width: 624px;
        height: 148px;
        margin: 16px 16px 36px 896px;
        padding: 0 0 1px 40px;
        border-radius: 8px;
        box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.16);
        background-color: #2c3945;
        z-index: 1;
        display: flex;
  }
  .Gautam-Adani {
    display: inline-block;
    width: 300px;
    height: 19px;
    margin: 25px 4px 12px 0;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: left;
    color: #d2d3d4;
  }
  .Connecting {
    width: 95px;
    height: 18px;
    margin: 12px 10px 4px 4.5px;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    display: inline-block;
  }
  .Call-23500 {
    width: 68px;
    height: 15px;
    margin: 4px 20px 9px 0;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #d2d3d4;
  }
  
  .Auto-Calling {
    width: 130px;
    height: 19px;
    margin: 8px 20px 11px 150px;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: left;
    color: #5fff96;
    display: inline-block;
  }
  .Auto-calling {
    width: 95px;
    height: 18px;
    display: inline-block;
    margin: 25px 0 8px;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }
  .Rectangle-9991 {
    width: 58px;
    height: 58px;
    margin: 38px 22px 15px 29px;
    padding: 25px 17px 24.1px;
    border-radius: 36px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ed3833;
  }
  .Rectangle-9992 {
    align-items: right;
    width: 134px;
    height: 147px;
    margin: 0 0 0 34px;
    padding: 0 0 49px 24px;
    background-color: #364757;
    
  }
  .Icon-material-call-end {
    display: inline-block;
    position: relative;
    bottom: 8px;
  }
  
  
  .open-modal-button {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  
  .close-modal-button {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  
  .modal-content {
    height: calc(100% - 40px);
    overflow-y: scroll;
  }
