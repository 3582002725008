.plan-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 50px 0;
  }
  .plan-container .plan-logo {
    height: 60px;
  }
  .plan-container .plan-intro-text {
    margin-top: 24px;
    font-family: Inter;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    color: #000;
  }
  .plan-container .plan-tabs {
    display: flex;
    justify-content: space-between;
    width: 800px;
    margin-top: 8px;
  }
  .plan-container .plan-tab {
    width: 260px;
    height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    margin: 40px 10px 0 0;
    padding: 10px 0 0;
    box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.1);
  }
  .plan-container .plan-tab.active {
    border-bottom: 5px solid #ed3833;
  }
  .plan-container .plan-tab .tab-content {
    display: flex;
    align-items: center;
  }
  .plan-container .plan-tab .tab-logo {
    height: 40px;
    margin-right: 8px;
  }
  .plan-container .plan-tab .tab-text-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 7px;
  }
  .plan-container .plan-tab .tab-title {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    text-align: left;
    color: #000;
  }
  .plan-container .plan-tab .tab-price {
    font-size: 14px;
    font-family: Inter;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.72px;
    text-align: left;
    color: #ed3833;
  }
  .plan-container .plan-separator {
    width: 100%;
    margin-top: 0px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  }
  .plan-container .plan-description {
    margin-top: 24px;
    font-family: Inter;
    font-size: 12px;
    text-align: center;
    color: #000;
    max-width: 500px; /* or another value that suits your design */
    line-height: 1.5;
    
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
  }
  .plan-container .plan-cards {
    display: flex;
    justify-content: center;
    margin-top: 24px;
  }
  .plan-container .plan-card {
    width: 250px;
    height: 300px;
    margin: 0 10px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    position: relative;
  }
  .plan-card-title {
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    text-align: center;
    color: #ed3833;
  }
  .plan-features {
    list-style: none;
    padding: 0;
    margin-top: 15px;
  }
  .feature-list-item {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: 500;
  }
  .check-mark {
    margin-right: 8px;
    height: 12px;
  }
  /* .plan-card-bottom {
    margin-top: auto; 
    flex-direction: column;
  } */
  .plan-card-bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 16px;
    width: 210px;
  }
  .plan-card-price {
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    text-align: center;
    color: #ed3833;
    margin-bottom: 24px; /* Add some spacing from the button */
  }
  .get-started-btn {
    padding: 6px 20px;
    border-radius: 4px;
    border: solid 1px #000;
    background-color: #fff;
    font-size: 10px;
    font-weight: 600;
    transition: background-color 0.3s ease;
    width: 208px;
  }
  .get-started-btn:hover {
    background-color: black; /* Change background color on hover */
    color: white; /* Change font color on hover */
  }