.AuthBackground {
  background: var(--secondary-color);
  height: 100vh;
}

.BackgroundCircleOne {
  border-radius: 50%;
  width: 490px;
  height: 490px;
  z-index: 0;
  position: fixed;
  top: 5%;
  left: 45%;
  opacity: 0.1;
  background-color: var(--white-color);
}

.BackgroundCircleTwo {
  border-radius: 50%;
  width: 235px;
  height: 235px;
  z-index: 0;
  position: fixed;
  top: 72%;
  left: 34%;
  opacity: 0.1;
  background-color: var(--white-color);
}

.LoginDiv {
  position: absolute;
  width: 300px;
  height: 300px;
  /* Center form on page horizontally & vertically */
  top: 45%;
  left: 50%;
  margin-top: -300px;
  margin-left: -200px;
}

.LoginDiv40 {
  top: 40%;
}

.LoginDiv50 {
  top: 50%;
}

.LoginContentHolder {
  width: 448px;
  /* height: 300px; */
  background: var(--white-color);
  border-radius: 10px;
  margin: 0;
  padding: 80px 56px;
}

.AuthLogoContainer {
  margin-bottom: 30px;
}

.AuthLogoContainer img {
  height: 40px;
}

.AuthHeadContainer {
  margin-bottom: 34px;
}

.DoosraBusinessHeader {
  /* font-family: Montserrat; */
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  /* line-height: 1.07; */
  margin: 0;
  margin-bottom: 10px;
  letter-spacing: normal;
}

.AuthSubHeader {
  font-size: 22px;
  font-weight: 500;
  margin: 40px;
  color: #454545;
  margin-top: 55px;
}

.BottomBorder {
  padding-top: 30px;
  border-bottom: solid 2px var(--mid-grey-color);
  margin-bottom: 30px;
}

.DoosraDefaultColor {
  color: var(--primary-color1);
}

.InputBox {
  padding-left: 5%;
  width: 100%;
  height: 44px;
  font-size: 16px;
  border-radius: 4px;
  border: solid 1px var(--light-grey-input-bg-color);
  margin-top: 7px;
  margin-bottom: 7px;
  background-color: var(--white-color);
}

.InputBox:focus {
  /* outline: #777777; */
  outline-color: #f8153b;
  /* -webkit-box-shadow: none; */
  /* box-shadow: none; */
}

.AuthButton {
  width: 100%;
  padding: 15px 50px 14px;
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.75px;
  text-align: center;
  color: var(--white-color);
  border-radius: 4px;
  border-width: 0;
  border: solid 1px var(--primary-color1);
  background-color: var(--primary-color1);
}

.AuthButtonInvert {
  border: solid 1px #939598;
  color: #939598;
  background-color: white;
}

.LogoutAllDevicesButton {
  width: 100%;
  padding: 15px 50px 14px;
  margin-top: 32px;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.75px;
  text-align: center;
  color: var(--white-color);
  border-radius: 4px;
  border-width: 0;
  border: solid 1px var(--primary-color1);
  background-color: var(--primary-color1);
}

.LogoutAllDevicePopup {
  width: 90%;
  margin-left: 5%;
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.LogoutAllDevices {
  margin-top: 32px;
  color: red;
  border: solid 1px red;
  background-color: white;
}

.AuthForgotPassword {
  /* text-decoration: underline; */
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  margin-top: 0;
  letter-spacing: normal;
  text-align: right;
  color: #42a1e2;
  cursor: pointer;
}

.AuthRememberOnDeviceContainer {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.AuthRememberOnDevice {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  margin: 0;
  letter-spacing: normal;
  text-align: left;
  color: #777777;
}

.AuthCheckbox {
  position: relative;
  top: 2px;
  right: 10px;
}

.PasswordInputHolder {
  display: grid;
  grid-template-columns: 92% 8%;
  /* display: flex; */
  /* justify-content: space-between; */
  align-items: center;
  margin-top: 7px;
  margin-bottom: 7px;
  padding: 15px 10px 14px 10px;
  border-radius: 4px;
  border: solid 1px var(--light-grey-input-bg-color);
  background-color: var(--white-color);
}

.PasswordInput {
  border: none;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  padding-right: 45px;
}

.PasswordVisibleToggle {
  /* position: relative; */
  /* top: 5px; */
}

.PasswordInvisibleToggle {
  /* position: relative; */
  /* top: 3px; */
}

.PasswordInput:focus,
.PasswordInput:focus {
  outline: none;
}

.TextCenter {
  text-align: center;
}

.QRCodeContainer {
  background: var(--secondary-color);
  height: 48px;
  margin-bottom: 10px;
  border-radius: 4px;
}

.QRCodeText {
  font-size: 15px;
  font-weight: bold;
  color: var(--white-color);
  padding-left: 20px;
  width: 215px;
  float: left;
}

.QRCodeIcon {
  float: right;
  margin: 12px;
}

.SignIntoDashboardContainer {
  background: var(--secondary-color);
  height: 48px;
  margin-bottom: 10px;
  border-radius: 4px;
}

.SignIntoDashboard {
  font-size: 15px;
  font-weight: bold;
  color: var(--white-color);
  padding-left: 20px;
  width: 230px;
  float: left;
  /* ------------- */
  width: 250px;
  margin: 13px;
}

.SignIntoRightArrowIcon {
  float: right;
  margin: 15px;
}

.AuthSuccessHolder {
  text-align: center;
  margin-bottom: 56px;
}

.PasswordCheckContainer {
  background-color: var(--light-grey-bg-color);
  border-radius: 4px;
  margin: 16px auto;
  padding: 12px 20px;
  text-align: initial;
}

.PasswordCheckContainer h1 {
  font-size: 14px;
  font-weight: 600;
}

/* .q {
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  color: #6d6e71;
} */

.PasswordCheckContentContainer {
  display: grid;
  grid-template-columns: 8% 92%;
  grid-template-rows: auto auto auto auto;
}

.PasswordCheckContentContainer img {
  padding-top: 3px;
}

.GreyText {
  margin: 0;
  font-size: 13px;
  color: var(--text-grey-color);
}

.GreenText {
  margin: 0;
  font-size: 13px;
  color: var(--text-green-color);
}

.ErrorContainer {
  min-height: 19px;
  font-size: 13px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  font-stretch: normal;
  font-style: normal;
  /* line-height: 3.92; */
  letter-spacing: normal;
  /* text-align: center; */
  color: red;
}

.ErrorContainerMargin {
  margin-top: 8px;
  margin-bottom: 6px;
}

.Error {
  width: fit-content;
  margin: 0;
}

.GetInTouch {
  padding: 14px 109px 14px;
}

.GetInTouch:hover,
.GetInTouch:focus {
  text-decoration: none;
  color: var(--secondary-color);
}

.OTPResent {
  display: flex;
  justify-content: center;
}

.OTPResent img {
  height: 14px;
  width: 14px;
  position: relative;
  top: 3px;
  margin-right: 4px;
}

.OTPResent p {
  color: green;
  margin: 0;
  font-size: 14px;
}

@media only screen and (max-width: 1500px) {
  .PasswordInput {
    border: none;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    padding-right: 0;
  }

  .PasswordVisibleToggle {
    /* float: right; */
    /* padding: 6px 4px; */
  }

  .PasswordInvisibleToggle {
    /* float: right; */
    /* padding: 4px 4px; */
  }
}

.login-link {
  color: #ed3833;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
}

.demo-link-container {
  margin-top: auto;
  margin-bottom: 30px;
  width: 336px;
}

.step-label {
  text-align: center;
  margin-top: 79px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  color: #939598;
  letter-spacing: normal;
  position: absolute;
}

.steps {
  display: flex;
  justify-content: space-evenly;
  width: 264px;
  margin: auto;
}

.otp-send-text {
  margin-top: -32px;
  color: #07aa3f;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
}

.step {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.step:not(:last-child)::after {
  content: '';
  height: 2px;
  background-color: #07aa3f;
  position: absolute;
  width: 90%;
  left: 40px;
  top: 50%;
}

.outer-circle {
  width: 40px;
  height: 40px;
  border: 2px solid #07aa3f;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inner-circle {
  width: 32px;
  height: 32px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.19;
  text-align: center;
  color: #07aa3f;
}

.step.active .inner-circle {
  background-color: #07aa3f;
  color: #fff;
}

.LoginButton {
  width: 100%;
  padding: 15px 50px 14px;
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.75px;
  text-align: center;
  color: var(--white-color);
  border-radius: 4px;
  border-width: 0;
  border: solid 1px #042f39;
  background-color: #042f39;
}

.SignupButton {
  width: 100%;
  padding: 15px 50px 14px;
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.75px;
  text-align: center;
  color: var(--white-color);
  border-radius: 4px;
  border-width: 0;
  border: solid 1px green;
  background-color: green;
}

.NextButton {
  width: 100%;
  padding: 15px 50px 14px;
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.75px;
  text-align: center;
  color: var(--white-color);
  border-radius: 4px;
  border-width: 0;
  border: solid 1px grey;
  background-color: grey;
}

.ResendButton {
  border: none;
  background: none;
  color: #ed3833;
  text-decoration: underline;
  cursor: pointer;
}

.AccountCreatedRootSection {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.BeginTourRootSection {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.AccountCreated {
  width: 540px;
  height: 524px;
  padding: 48px 82px;
  border-radius: 8px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
}

.SuccessImage {
  width: 100px;
  height: 100px;
  /* margin: 0 144px 40px 148px; */
  object-fit: contain;
}

.Account-created-welcome-to-Alohaa {
  width: 392px;
  height: 26px;
  margin: 40px 0 24px;
  font-family: Inter;
  font-size: 22px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.82;
  letter-spacing: normal;
  text-align: center;
  color: #838383;
  white-space: nowrap;
}

.Your-7-day-free-trial-starts-now {
  width: 358px;
  height: 29px;
  margin: 24px 17px 40px;
  font-family: Inter;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  white-space: nowrap;
}

.BeginTour {
  width: 336px;
  height: 48px;
  margin: 40px 28px 18px;
  border-radius: 4px;
  background-color: #07aa3f;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.BeginTourLabel {
  width: 98px;
  height: 19px;
  font-family: Inter;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.4;
  text-align: center;
  color: #fff;
}

.SkipTour {
  width: 336px;
  height: 20px;
  margin: 24px 28px 17px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: #ed3833;
}

.FirstTimeUser {
  width: 336px;
  height: 42px;
  margin: 17px 28px 0;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.BeginTourFirstTimeText {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  text-align: center;
  display: block;
}

.SignUpSuccessHolder {
  text-align: center;
  margin-bottom: 32px;
}

.AlreadyHaveAccount {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  margin-top: 24px;
}

.free-trial-banner {
  /* top: 0; */
  /* left: 0; */
  position: fixed;
  width: 100%;
  height: 56;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  background-color: #000;
  z-index: 100;
}

.free-trial-text {
  flex: 1;
  font-family: Inter;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.22;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  font-family: 'Montserrat';
}

.free-trial-upgrade {
  color: #000;
  background-color: #fff;
  padding: 4px;
  border-radius: 16px;
  margin-left: 16px;
  cursor: pointer;
}

.free-trial-text a:hover {
  color: #afafaf;
}

.view-plan-link {
  color: white;
  text-decoration: underline;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.22;
  letter-spacing: normal;
  margin-left: 5px;
}

.close-button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}
