.exportbutton {
  border-radius: 4px;
  border: 2px solid #07aa3f;
  font-size: 14px;
  font-weight: bold;
  color: #07aa3f;
  background-color: #fff;
  padding: 3px 10px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: 10px;
}

.exportbutton:hover {
  background-color: #07aa3f;
  color: #fff;
}

.leadListSelect {
  width: 213px;
  height: 32px;
  display: flex;
  margin: 16px 16px 16px;
  padding: 7px 0px 8px 13px;
  border-radius: 4px;
  border: solid 1px #ed3833;
  background-color: #fff;
}

.Select-lead-list {
  width: 180px;
  height: 17px;
  margin: 0 20px 15px 0;
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  border: none;
  letter-spacing: normal;
  text-align: left;
  color: #ed3833;
}

.report-stats {
  height: 36px;
  margin: 0 24px 0 0;
  padding: 9px 4px;
  /* border-radius: 4px;
  border: solid 1px #d2d3d4; */
  background-color:transparent;
}

.report-stats-text {
  margin: 0 0 0 16px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: right;
  color: #196cca;
}

.Rectangle-9826 {
  width: 368px;
  height: 56px;
  margin: 16px 16px 40px;
  padding: 10px 31px;
  border-radius: 4px;
  background-color: #f4f4f4;
  color: #000;
}
.Download-the-sample-sheet {

  width: 306px;
  height: 36px;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  z-index: 99999; 
}

/*  */

.date-range-container {
  display: flex;
  align-items: center;
  font-family: Montserrat;
  gap: 8px;
  margin-right: 1%;
}

.date-range-label {
  color: #666;
  font-size: 13px;
  margin-bottom: 0px;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: right;
  color: #939598;
}

.date-range-inputs {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 1px;
}

.date-input {
  border: none;
  outline: none;
  font-size: 13px;
  color: #999;
  padding: 5px 8px;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #939598;
}

.search-date {
  border: none;
  outline: none;
  font-size: 13px;
  color: white;
  padding: 5px 8px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
}

.date-range-separator {
  color: #999;
  padding: 0 2px;
}