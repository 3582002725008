.popup {
    position: fixed;
    top: 0;
    right: 0;
    width: auto;
    height: auto;
    margin: 16px 16px 36px 896px;
    padding: 0 0 1px 40px;
    border-radius: 8px;
    box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #2c3945;
    z-index: 9999;
} 

.popup-header {
    display: flex;
    align-items: flex-end;
    padding-top: 20px;
    background-color: #2c3945;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  
.popup-profile {
    margin-right: 16px;
}
  
.popup-name {
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 4px;
}

.popup-status {
    display: flex;
    align-items: flex-end;
    color: #fff;
    margin-top: 10px;
    padding: 10px;
}
  
.popup-buttons {
    display: flex;
    align-items: center;
    padding: 10px;
    margin-right: 25px;
    background-color: #2c3945;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.popup-buttons button:not(:last-child) {
    margin-right: 8px;
}
    