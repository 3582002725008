.login-main {
  /* width: 100vw; */
  /* height: 100vh; */
  display: flex;
  flex-direction: row;
}

.section-1 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.login-container {
  width: 336px;
  text-align: center;
  margin-top: 16px;
}

.login-container h1 {
  font-size: 22px;
  font-weight: 500;
  color: #454545;
}

.login-container-logo {
  margin-bottom: 30px;
  margin-top: 10px;
}

.AuthButton {
  position: relative;
  /* Add any additional styles for the button */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0; /* Remove padding to prevent height change */
}

.ButtonText {
  opacity: 1; /* Show the text when not loading */
  transition: opacity 0.2s ease-in-out; /* Add a transition for smooth fading */
}

.ButtonSpinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 22px;
  height: 22px;
  border: 2px solid #ffffff;
  border-top: 2px solid #04aa6d;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.divider {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #d2d3d4;
  display: flex;
  align-items: center;
}

.divider::before,
.divider::after {
  flex: 1;
  content: '';
  padding: 1px;
  background-color: #d2d3d4;
  margin: 5px;
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.ButtonLoading {
  pointer-events: none; /* Disable button interaction during loading */
  opacity: 0.7; /* Adjust opacity as needed */
}

.ButtonLoading .ButtonText {
  opacity: 0; /* Hide the text when loading */
  height: 22px;
}
