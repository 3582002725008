.panel-container {
  position: absolute;
  /* top: 0;
  right: 0;
  height: 100vh; */
}
.Rectangle-10578 {
  width: 370px;
  height: 70px;
  margin: 0px 15px 10px 15px;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #454545;
}
.panel {
  width: 400px;
  height: 100vh;
  overflow: none;
  position: fixed;
  z-index: 100;
  right: -400px; /* Start off-screen */
  background-color: #333333;
  box-shadow: -6px 0 6px 0 rgba(0, 0, 0, 0.22);
  transition: right 0.3s ease-in-out;
}
.panel-content-logo {
  position: absolute;
  padding: 15px;
  margin-bottom: 100px;
  transform: translate(60%, 70%);
}
.panel-content-refresh {
  position: fixed;
  width: 0px;
  top:-30px;
  transform: translate(60%, 70%);
}
.panel-content-button {
  width: 400px;
  font-weight: bold;
}
.panel.open {
  right: 0; /* Slide in */
}
.toggle-area-call {
  width: 40px;
  height: 100px;
  flex-direction: column;
  position: fixed;
  top:110px;
  right: 0; /* Position next to the panel */
  border-top-left-radius: 7px; /* Rounded corner at top left */
  border-bottom-left-radius: 7px; /* Rounded corner at bottom left */
  background-color: green;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: right 0.3s ease-in-out;
  cursor: pointer;
  box-shadow: -3px 0 3px 0 rgba(0, 0, 0, 0.22);
}
.toggle-area {
  width: 40px;
  height: 100px;
  position: fixed;
  flex-direction: column;
  top:110px;
  right: 0; /* Position next to the panel */
  border-top-left-radius: 7px; /* Rounded corner at top left */
  border-bottom-left-radius: 7px; /* Rounded corner at bottom left */
  background-color: #ed3833;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: right 0.3s ease-in-out;
  cursor: pointer;
  box-shadow: -3px 0 3px 0 rgba(0, 0, 0, 0.22);
}
.timer {
  font-family: Montserrat;
  font-size: 11px;
  padding: 10px 1px 0px 1px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #fff;
}
.panel.open + .toggle-area {
  right: 400px; /* Move along with the panel */
}
.panel.open + .toggle-area-call {
  right: 400px; /* Move along with the panel */
}
.line-break {
  width: 400px;
  height: 0;
  margin: 135px 0 5px 0;
  border: solid 1px #707070;
}
.panel-content-header {
  position: absolute;
  width: auto;
  height: 20px;
  transform: translate(130%, 70%);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}
.connected-group {
  position:relative;
}
.connected-line {
  position: relative;
  width: 370px;
  height: 70px;
  margin: 0px 15px 10px 15px;
  padding: 10px;
  border-radius: 7px;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.16);
  background-color: #07aa3f;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto 1fr auto;
  align-items: center;
  gap: 4px; /* Adjust the gap as needed */
}
.connected-line-name {
  grid-column: 1;
  grid-row: 1;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #fff;
}
.connected-line-number {
  grid-column: 1;
  grid-row: 3;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #fff;
}
.connected-line-status {
  grid-column: 3;
  grid-row: 3;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #fff;
}
.connected-line-timer {
  grid-column: 3;
  grid-row: 1;
  margin-left: 40px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #fff;
}
.connected-line-info {
  position: relative;
  width: 370px;
  height: 210px;
  margin: 0px 15px 10px 15px;
  padding: 16px 0;
  border-radius: 7px;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.16);
  border: solid 0.5px #939598;
  background-color: #333;
  color: #fff;
}
.details {
  font-family: Montserrat;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #555;
  border-bottom: 1px solid #555;
  padding: 10px;
}

.counts {
  font-family: Montserrat;
  font-size: 16px;
  display: flex;
  font-weight: 500;
  color:#fff;
  justify-content: space-between;
  /* border-top: 1px solid #555; */
  /* border-bottom: 1px solid #555; */
  margin-left:20px;
  margin-right:20px;
  padding: 5px;
}

.email {
  font-family: Montserrat;
  font-size: 16px;
  padding: 10px;
}
.amount, .date {
  padding: 5px;
  text-align: center;
}
.label {
  font-family: Montserrat;
  font-size: 16px;
  display: block;
  margin-bottom: 5px;
}
.value {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  display: block;
}
.overdue {
  font-family: Montserrat;
  font-size: 16px;
  padding: 10px;
  text-align: center;
}
.call-end-button {
  position: fixed;
  display: inline;
  bottom: 0px;
  width: 360px;
  height: 64px;
  border-radius: 10px;
  margin: 110px 0px 10px 20px;
  background-color: #ea3833;
}
.mute-button {
  position: fixed;
  display: inline;
  bottom: 80px;
  padding: 15px 60px;
  margin: 10px 25px 25px 40px;
  border-radius: 7px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #ed3833;
  background-color: #272727;
  cursor: pointer; /* Cursor pointer */
  transition: background-color 0.3s, transform 0.1s, box-shadow 0.1s; /* Smooth transition for effects */
}
.mute-button:active {
  background-color: #1f1f1f; /* Slightly darker background color on press */
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16); /* Adjusted box-shadow for a pressed effect */
  transform: translateY(2px); /* Slight downward movement to simulate pressing */
}
.hold-button {
  position: fixed;
  display: inline;
  bottom: 80px;
  padding: 16px 63px;
  margin: 10px 25px 25px 210px;
  border-radius: 7px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #ed3833;
  background-color: #272727;
  cursor: pointer; /* Cursor pointer */
  transition: background-color 0.3s, transform 0.1s, box-shadow 0.1s; /* Smooth transition for effects */
}
.hold-button:active {
  background-color: #1f1f1f; /* Slightly darker background color on press */
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16); /* Adjusted box-shadow for a pressed effect */
  transform: translateY(2px); /* Slight downward movement to simulate pressing */
}

.mute-button-pressed {
  position: fixed;
  display: inline;
  bottom: 80px;
  padding: 15px 60px;
  margin: 10px 25px 25px 40px;
  border-radius: 7px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #f7f7f7;
  background-color: #272727;
  cursor: pointer; /* Cursor pointer */
  transition: background-color 0.3s, transform 0.1s, box-shadow 0.1s; /* Smooth transition for effects */
}

.mute-button-pressed:active {
  background-color: #1f1f1f; /* Slightly darker background color on press */
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16); /* Adjusted box-shadow for a pressed effect */
  transform: translateY(2px); /* Slight downward movement to simulate pressing */
}
.hold-button-pressed {
  position: fixed;
  display: inline;
  bottom: 80px;
  padding: 16px 63px;
  margin: 10px 25px 25px 210px;
  border-radius: 7px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #f7f7f7;
  background-color: #272727;
  cursor: pointer; /* Cursor pointer */
  transition: background-color 0.3s, transform 0.1s, box-shadow 0.1s; /* Smooth transition for effects */
}

.hold-button-pressed:active {
  background-color: #1f1f1f; /* Slightly darker background color on press */
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16); /* Adjusted box-shadow for a pressed effect */
  transform: translateY(2px); /* Slight downward movement to simulate pressing */
}
/* Switch */
.switch-button {
  width: 50px;
  height: 25px;
  background-color: grey;
  border-radius: 25px;
  cursor: pointer;
  position: relative;
  margin-top: 60px;
  margin-left: 320px;
}
.switch-button.on {
  background-color: green;
}
.switch-knob {
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 2.5px;
  left: 2.5px;
  transition: left 0.3s;
}
.switch-knob.on {
  left: 27.5px;
}
.disconnet-info {
  position: absolute;
  margin: 200px 30px 0 35px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #fff;
}
.waiting-info {
  position: absolute;
  width: 400px;
  height: 20px;
  margin-top: 200px;
  display: flex; /* Enables flexbox layout */
  justify-content: center; /* Horizontally centers the child div */
  align-items: center; /* Vertically centers the child div */
}
.call-status {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #fff; 
}
.dialing-group {
  position: relative;
}
.dialing-line-0 {
  position: relative;
  width: 370px;
  height: 70px;
  margin: 0px 15px 10px 15px;
  padding: 10px;
  border-radius: 7px;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.16);
  background-color: #484848;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto 1fr auto;
  align-items: center;
  gap: 4px; /* Adjust the gap as needed */
}
.dialing-line-name {
  grid-column: 1;
  grid-row: 1;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #fff;
}
.dialing-line-number {
  grid-column: 1;
  grid-row: 3;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #fff;
}
.dialing-line-status {
  grid-column: 3;
  grid-row: 3;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #fff;
}
.dialing-line-1 {
  position: absolute;
  width: 370px;
  height: 70px;
  margin: 160px 15px;
  padding: 10px;
  border-radius: 7px;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.16);
  background-color: #484848;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto 1fr auto;
  align-items: center;
  gap: 4px; /* Adjust the gap as needed */
}
.dialing-line-2 {
  position: absolute;
  width: 370px;
  height: 70px;
  margin: 240px 15px;
  padding: 10px;
  border-radius: 7px;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.16);
  background-color: #484848;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto 1fr auto;
  align-items: center;
  gap: 4px; /* Adjust the gap as needed */
}
.dialing-line-3 {
  position: absolute;
  width: 370px;
  height: 70px;
  margin: 320px 15px;
  padding: 10px;
  border-radius: 7px;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.16);
  background-color: #484848;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto 1fr auto;
  align-items: center;
  gap: 4px; /* Adjust the gap as needed */
}