.BottomTabs {
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
}
.BottomCardContainer {
  height: 126px;
  width: 650px;
  background-color: white;
  border-radius: 3cap;
  padding-left: 2%;
  padding-right: 2%;
  padding-top: 2%;
  padding-bottom: 2%;
  margin-bottom: 2%;
  min-width: 600px;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 6px 3px 0px rgba(0, 0, 0, 0.05);
}
.iconCard {
  height: 73px;
  width: 73px;
  background-color: #42a1e2;
  border-radius: 20%;
}
.cardItems {
  width: 90px;
  height: 90px;
  border-radius: 16px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.16);
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid #e9e9e9;
}
.cardItemImg {
  width: 38px;
  height: 38px;
}
.cardText {
  font-size: 16px;
  color: #07aa3f;
  margin-top: 5px;
  font-weight: 600;
  text-align: center;
  font-family: 'Montserrat';
  font-style: normal;
  line-height: normal;
}
.SidebarContainer {
  position: absolute;
  z-index: 3;
  display: flex;
  justify-content: flex-end;
  height: 80vh;
  width: -webkit-fill-available;
}
.SidebarBox {
  height: calc(100vh - 72px);
  width: 28vw;
  background-color: white;
  box-shadow: -3px 0px 4px 0px rgba(0, 0, 0, 0.16);
  overflow: auto;
}
.SideBarHeader {
  height: 54px;
  background-color: white;
  border-bottom: 1px solid #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.CancelText {
  margin-right: 3%;
  font-size: 16px;
  font-style: normal;
  color: #f8153b;
  font-weight: 600;
  cursor: pointer;
}
.SaveText {
  margin-right: 5%;
  font-size: 16px;
  font-style: normal;
  color: #07aa3f;
  font-weight: 600;
  cursor: pointer;
}
.StartIvrHeading {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  color: #07aa3f;
}
.SubHeading {
  margin-left: 40px;
  margin-top: 14px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  color: #9b9b9b;
}
.HeaderFooter {
  margin-left: 40px;
  margin-top: 11px;
}
.FooterSelectBox {
  height: 36px;
  width: 180px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
}
.UploadAudioButton {
  width: 157px;
  height: 36px;
  margin-left: 40px;
  padding: 24px 12px;
  border-radius: 4px;
  border: 1px solid var(--Alohaa-Green, #07aa3f);
  background: var(--White, #fff);
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.16);
  margin-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.UploadAudioText {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  color: #07aa3f;
  cursor: pointer;
  line-height: 1;
  text-align: center;
  margin: 0;
}
.DailpadContainer {
  display: grid;
  grid-template-rows: 100px 100px 100px;
  grid-template-columns: 100px 100px 100px;
  margin-left: 40px;
  margin-top: 30px;
}
.DailpadItem {
  width: 76px;
  height: 76px;
  background-color: #fff;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.16));
}
.DailpadText {
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  color: #5c5c5c;
}
.DailPadSelected {
  border-width: 2px;
  border-color: #07aa3f;
  border-style: solid;
}
.DailPadTickMark {
  width: 24px;
  height: 24px;
  position: absolute;
  bottom: -5px;
  right: -5px;
}
.MenuOptions {
  height: 36px;
  width: 31px;
  background-color: #5c5c5c;
  border-radius: var(--4, 4px) var(--4, 4px) var(--0, 0px) var(--0, 0px);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  margin-right: 5px;
}
.SaveButton {
  width: 104px;
  height: 40px;
  border-radius: 8px;
  background: #07aa3f;
  box-shadow: 0px 6px 3px 0px rgba(0, 0, 0, 0.05);
  color: #fff;
  font-weight: 600;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.SaveButtonContainer {
  position: absolute;
  top: 3%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.CallFlowHeader {
  width: 100vw;
  height: 54px;
  background-color: #fff;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.16);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  z-index: 2;
}
.CallFlowHeaderLogo {
  width: 28px;
  height: 24px;
}
.UnStyledButton {
  border: none;
  background: none;
}
.ProfileButton {
  height: 42px;
  width: 42px;
  background-color: #196cca;
  border-radius: 50px;
  border: none;
  margin-right: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.NavbarContainer {
  background-color: #f6f6f6;
  width: 96px;
  height: 100vh;
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
}
.NavbarItemContainer {
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.NavbarItems {
  height: 64px;
  width: 64px;
  background-color: #fff;
  box-shadow: 0px 1px 1.6px 0px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  min-height: 64px;
  min-height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}
.CallFlowSubHeader {
  width: -webkit-fill-available;
  height: 72px;
  position: absolute;
  z-index: 1;
  background-color: #fff;
  border-bottom: 1px solid #e9e9e9;
  margin-top: 1px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.BackButton {
  height: 40px;
  width: 40px;
  border-radius: 50px;
  background-color: #fff;
  border: none;
  box-shadow: 0px 4px 3px 0px rgba(0, 0, 0, 0.05);
  margin-left: 16px;
  margin-right: 16px;
}
.SaveAsDraftButton {
  width: 134px;
  height: 40px;
  background-color: #fff;
  border-radius: 8px;
  margin-left: 16px;
  margin-right: 16px;
  border: 1px solid #07aa3f;
}
.GoLiveButton {
  width: 134px;
  height: 40px;
  background-color: #07aa3f;
  border-radius: 8px;
  border: none;
}
.GoLiveText {
  color: #fff;
}
.MenuAudioContainer {
  margin-top: 40px;
  margin-left: 40px;
}
.MenuAudioSubHeading {
  margin-top: 14px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  color: #9b9b9b;
}
.NavbarImage {
  height: 24px;
  width: 24px;
}
.TopbarLogo {
  margin: auto 0;
  float: right;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #196cca;
  object-fit: contain;
}
.handle {
  width: 9px;
  height: 9px;
}
.agentCheckboxContainer{
  margin-left: 30px;
  margin-top: 14px;
}
.agentViaApiInputContainer{
  display: flex;
  flex-direction: column;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.agentViaApiInput{
  width: 180px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #D9D9D9;
  background: #fff;
  margin-top: 11px;
}
.agentViaApiContainer{
  margin-left: 40px;
  margin-top: 38px;
}
.webhookBodyContainer{
  margin-left: 40px;
  margin-top: 40px;
}
.webhookInputContainer{
  display: flex;
  flex-direction: column;
}
.webhookInput{
  width: 336px;
  height: 36px;
  margin-top: 11px;
  border-radius: 4px;
  border: 1px solid #D9D9D9;
  background: #FFF;
}
.webhookText{
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.PopupTemplateContainer{
  width: 368px;
  height: 164px;
  margin-top: 24px;
  margin-left: 16px;
  margin-right: 16px;
  border-radius: 4px;
  border: 1px solid var(--Light-Gray, #D9D9D9);
  background: linear-gradient(180deg, #FFF 0%, #F6F6F6 100%);
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.16);
  align-items: center;
  display: flex;
  justify-content: center;
}
.PopupTemplateText{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4px;
  color: var(--Dark-Gray, #474747);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.28px;
}
.CallFlowDocumentaion{
  font-size: 16;
  font-style: normal;
  font-weight: 400;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  margin-top: 20px;
  bottom: 29%;
}
.DocumentaionContainer{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 30px;
  margin-left: 40px;
}