.App {
    font-family: sans-serif;
    text-align: center;
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
  }
  
  .modal-main {
    position: fixed;
    background: white;
    width: 80%;
    height: auto;
    max-height: 80%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    overflow-y: scroll;
  }
  
  
  .display-block {
    display: block !important;
  }
  
  .display-none {
    display: none;
  }
  
  .Otherbutton {
    width: 116px;
    height: 32px;
    margin: 8px;
    padding: 7px;
    border-radius: 4px;
    border: none;
    font-size: 12px;
    font-weight: bold;
    color: black;
  }
  
  .react-switch-checkbox {
    height: 0;
    width: 0;
    visibility: hidden;
  }
  
  .react-switch-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 50px;
    height: 29px;
    background: grey;
    border-radius: 100px;
    position: relative;
    left:15px;
    bottom:15px;
    transition: background-color .2s;
  }
  
  .react-switch-label .react-switch-button {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 25px;
    height: 25px;
    border-radius: 45px;
    transition: 0.2s;
    background: #fff;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  }
  
  .react-switch-checkbox:checked + .react-switch-label .react-switch-button {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }
  
  .react-switch-label:active .react-switch-button {
    width: 60px;
  }
  
  .switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 26px;
  }
  
  .switch input {
  opacity: 0;
  width: 0;
  height: 0;
  }
  
  
  .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  }
  
  .slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  }
  
  input:checked + .slider {
  background-color: green;
  }
  
  input:focus + .slider {
  box-shadow: 0 0 1px green;
  }
  
  input:checked + .slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
  }
  
  
  .slider.round {
  border-radius: 18px;
  }
  
  .slider.round:before {
  border-radius: 50%;
  }
  

  .drag-and-drop {
    width: 368px;
    height: 128px;
    margin: 4px 16px 16px 0px;
    padding: 55px 53px 55px 47px;
    border-radius: 4px;
    border: dashed 1px #d1d3d4;
    background-color: #fff;
  }

  .uploadLabel {
    width: 268px;
  height: 18px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #939598;
  }