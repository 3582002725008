.react-toggle-checkbox {
    height: 0;
    width: 0;
    visibility: hidden;
}

.react-toggle-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 50px;
    height: 15px;
    background: grey;
    border-radius: 100px;
    position: relative;
    left: 15px;
    bottom: 15px;
    transition: background-color .2s;
}

.react-toggle-label .react-toggle-button {
    content: '';
    position: absolute;
    top: -6px;
    left: 0px;
    width: 25px;
    height: 25px;
    border-radius: 45px;
    transition: 0.2s;
    background: #f8f9f5;
    box-shadow: 0 5px 5px 2px rgba(10, 10, 10, 0.29);
}

.react-toggle-checkbox:checked+.react-toggle-label .react-toggle-button {
    left: calc(100% - 0px);
    transform: translateX(-100%);
}

.react-toggle-label:active .react-toggle-button {
    width: 60px;
}