.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-text {
  visibility: hidden;
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  z-index: 1;
  white-space: nowrap;
}

.arrow-right {
  position: absolute;
  top: 50%;
  left: 100%;
  border-width: 6px;
  border-style: solid;
  border-color: transparent transparent transparent #333;
  transform: translateY(-50%);
}

.arrow-left {
  position: absolute;
  top: 50%;
  right: 100%;
  border-width: 6px;
  border-style: solid;
  border-color: transparent #333 transparent transparent;
  transform: translateY(-50%);
}

.arrow-top{
  position: absolute;
  bottom: 55%;
  left:42%;
  border-width: 13px;
  border-style: solid;
  border-color: transparent transparent transparent #306cca;
  transform: translateX(-50%);
  transform-origin: 0 0;
  transform: rotate(270deg)
}

.tooltip-text.left {
  right: calc(100% + 10px);
  top: 50%;
  transform: translateY(-50%);
}

.tooltip-text.bottom {
  top: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
}

.tooltip-text.right {
  left: calc(100% + 10px);
  top: 50%;
  transform: translateY(-50%);
}

.tooltip-text.top {
  bottom: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
}
