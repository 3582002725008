.main-container {
    width: 400px;
    height: 318px;
    margin: 0px 162px 3px 0px;
    padding: 0 0 14px;
    border-radius: 4px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.16);
    background-color: #fff;
    /* overflow: scroll; */
    z-index: 10;
    position: absolute;
  }

  .header-box {
    width: 400px;
    height: 32px;
    margin: 0 0 2px;
    padding: 0 10px 0 0;
    background-color: #196cca;
    display: flex;
    justify-content: space-between;
  }

  .header-text {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 1.2px;
    text-align: right;
    color: #fff;
    display: inline-block;
    position: relative;
    margin: 0;
  }

  .label {
    margin: 0px 97px 0px 0px;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: left;
    color: #00515a;
    padding: 10px;
  }