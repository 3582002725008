.info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 812px;
  height: 395px;
  -webkit-backdrop-filter: blur(4.8px);
  backdrop-filter: blur(4.8px);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
}

.icon-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.icon {
  width: 50px;
  height: auto;
}

.heading {
  padding: 1rem;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: center;
  color: #196cca;
}

.text {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: center;
  color: #373a3c;
}

.addons-icon {
  width: 30.8px;
  height: 40px;
  margin: 0 330.2px 24px 331px;
}

.SearchButton {
  width: 176px;
  height: 48px;
  background-color: #ed3833;
  border: none;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  margin-top: 10px;
}
.button-black {
  width: 176px;
  height: 48px;
  background-color: #000000;
  border: none;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  padding: 1rem;
}

/* VerifiedContact.css */
/* .verifiedContactInput {
  width: 594px;
  height: 48px;
  border-radius: 4px;
  border: solid 1px #939598;
  padding-left: 15px;
} */
